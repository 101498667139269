<template>
  <div class="container overview-detail-page">
    <div class="row overview-detail-content">
      <div class="col-12 banner">
        <img :src="image_url + overview.image" :alt="overview.title">
      </div>
      <div class="col-12 description" v-if="overview.description" v-html="content"></div>
      <div class="col-12 collapse" v-if="extra_content" v-html="extra_content"></div>
      <div class="col-12" :class="{'read-more': !is_expanded, 'read-less': is_expanded}" v-if="extra_content">
        <a href="#" @click.prevent="expandCollapseContent" v-if="is_expanded">

          &lt; {{$t('core.Base.READ_LESS') }}
        </a>
        <a href="#" @click.prevent="expandCollapseContent" v-else>

         {{ $t('core.Base.READ_MORE') }} >
        </a>
      </div>
    </div>
    <div class="row overview-timing" v-if="overview.opening_time && overview.opening_time.length > 0">
      <div class="col-12 opening-hours-title">
        <h3> {{ overview.opening_time_title }} </h3>
      </div>
      <div class="col-12 opening-hours-table-container">
        <opening-hours-table :hours="overview.opening_time"></opening-hours-table>
      </div>
    </div>
    <div class="row map-location" v-if="overview.latitude && overview.longitude">
      <div class="col-12 map-location-title">
        <h3> {{ overview.map_title }} </h3>
      </div>
      <div class="col-12 map-location-chart">
        <iframe :src="'https://maps.google.com/maps?q=' +overview.latitude+',' +overview.longitude+ '&z=15&output=embed'" frameborder="0"></iframe>
      </div>
    </div>
    <div class="row overview-our-photos-row" v-if="overview.photobook_gallary && overview.photobook_gallary.length">
      <div class="col-12">
        <h3 class="our-photos-title"> {{ overview.album_title }} </h3>
      </div>
      <div class="col-12 overview-our-photos-container">
        <div id="overview-our-photos" class="carousel slide" data-ride="carousel">
          <div class="carousel-inner text-center" role="listbox">
            <div class="carousel-item" :class="{'active': index==0}" v-for="(image, index) in overview.photobook_gallary" :key="index">
              <img class="d-block" :src="image_url + image.overview_image" >
            </div>
            
          </div>
          <a class="carousel-control-prev" href="#overview-our-photos" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">{{$t('core.Base.PREVIOUS')}}</span>
          </a>
          <a class="carousel-control-next" href="#overview-our-photos" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">{{$t('core.Base.NEXT')}}</span>
          </a>
        </div>
      </div>
    </div>
    <router-link tag="a" v-if="backRoutePath" :to="backRoutePath + '?id=' + overview.id" class="btn btn-theme back-route-path-btn"></router-link>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import OpeningHoursTable from '../components/OpeningHoursTable.vue';
  
  export default {
    components: { OpeningHoursTable },
    
    computed: {
      ...mapGetters({ 
        settingsId: 'getSettingId',
        backRoutePath: 'getLastOverviewPath'
       }),
      id() {
        if( this.$route && this.$route.params && this.$route.params.id ){
          return this.$route.params.id;
        }
        return null;
      },
      content () {
        if( this.overview && this.overview.description ){
          return this.overview.description.split("<p>##READ_MORE_BUTTON##</p>")[0];
        }
        return null;
      },
      description () {
        if( this.overview && this.overview.description ){
          return this.overview.description.replace("##READ_MORE_BUTTON##", "");

        }
        return null;
      },
      extra_content () {
        if( this.overview && this.overview.description ){
          let content = this.overview.description.split("<p>##READ_MORE_BUTTON##</p>");
          if( content.length > 1){
            return content[ 1 ];
          }
        }
        return null;
      }
    },

    data () {

      return {
        image_url: 'https://app.tenzer.nl',
        overview: [],
        is_expanded: false
      }
    },
    mounted () {
      this.loadOverviewDetailData()
    },

    methods: {
      loadOverviewDetailData () {
        this.$root.$load(true)
        const settingsId = this.settingsId
        this.$http.post(`get-overview-detail/${this.id}?setting_id=${settingsId}`)
          .then((response) => {
            if (response.status === 200) {
              if( response.data && response.data.data ){
                
                this.overview = response.data.data;
              }
            }
          })
          .catch((e) => { console.log(e) })
          .finally(() => { this.$root.$load(false) })
      },
      expandCollapseContent () {
        this.$nextTick( () => {
          window.jQuery(".col-12.collapse").collapse("toggle");
          this.$set( this, 'is_expanded', ( !this.is_expanded ) );
        })
        
      }
    }
  }
</script>
